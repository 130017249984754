import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from "@angular/core"
import { Subscription } from "rxjs"
import { Router, ActivatedRoute } from "@angular/router"
import { AuthService } from "@puntaje/shared/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "usuario.component.html"
})
export class UsuarioComponent implements OnInit, OnDestroy {
    loggedLayoutTitle: string = "Datos del usuario"
    usuarioId: number
    private sub: Subscription

    constructor(
        protected titleService: TitleService,
        protected authService: AuthService,
        protected router: Router,
        protected route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.usuarios.usuarios.show")
        this.sub = this.route.params.subscribe(params => {
            this.usuarioId = +params["id"]
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
